export const API_CHAT = process.env.REACT_APP_API_CHAT_URL;
export const INITIAL_WS_CONFIG = {
  query: null,
  transports: ['websocket', 'polling'],
};
export const INITIAL_CONTEXT_VALUE = {
  socket: null,
  newMessage: {},
  typing: {},
};
export const FETCH_OPTIONS = {
  method: 'get',
  url: `${API_CHAT}v2/conversation`,
  delay: 750,
  query: {
    limit: 20,
  },
};

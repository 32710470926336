/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import iSellerLogo from '../assets/images/logos/integratedApps/iseller-logo.png';
import JubelioLogo from '../assets/images/logos/integratedApps/jubelio-logo.png';
import BantuDagangLogo from '../assets/images/logos/integratedApps/bantu-dagang-logo.png';
import { getIntegrationAccount } from '../services/Api/Baleomol/module/integration';
import { useAuth } from './useAuth';

const APP_LIST = [
  {
    name: 'Jubelio',
    site: 'https://jubelio.com/',
    id: 'jubelio',
    image: JubelioLogo,
    isNew: false,
    isConnected: false,
    logoHeight: '48px',
    active: false,
  },
  {
    name: 'Bantu Dagang',
    site: 'https://bantudagang.com/',
    id: 'bantu-dagang',
    image: BantuDagangLogo,
    isNew: false,
    isConnected: false,
    logoHeight: '48px',
    active: true,
  },
  // {
  //   name: 'iSeller',
  //   site: 'https://www.isellercommerce.com/',
  //   id: 'iseller',
  //   image: iSellerLogo,
  //   isNew: true,
  //   isConnected: false,
  //   logoHeight: '36px',
  //   active: false,
  // },
];

const IntegrationContext = createContext();

export const IntegrationProvider = ({ children }) => {
  const [list, setList] = useState([...APP_LIST]);
  const [data, setData] = useState({});
  const { user } = useAuth();

  const insertIntoData = (id, data) => {
    const time = new Date().getTime();
    setData((prev) => {
      return {
        ...prev,
        [id]: {
          lastUpdate: time,
          data,
        },
      };
    });
  };

  const setConnected = useCallback(
    (id, isConnected) => {
      const index = list.findIndex((item) => item.id === id);
      if (index >= 0) {
        const newList = [...list];
        newList[index].isConnected = isConnected;
        setList([...newList]);
      }
    },
    [list],
  );

  const fetchAllData = async () => {
    const activeAppIds = list
      .filter(({ active }) => active)
      .map(({ id }) => id);

    try {
      const responses = await Promise.all(
        activeAppIds.map((id) =>
          getIntegrationAccount({
            token: user?.token,
            app: id,
          }),
        ),
      );

      responses.forEach((res, i) => {
        const id = activeAppIds[i];
        if (res.status === 200 && res.data?.success) {
          const { data: appData } = res.data;
          insertIntoData(id, appData);
          setConnected(id, true);
        }
      });
    } catch (err) {
      console.error({ 'Error while executing fetchAllData()': err });
    } finally {
      // fix me
    }
  };

  const value = useMemo(() => {
    return {
      list,
      data,
      setList,
      setData,
      insertIntoData,
      setConnected,
    };
  }, [list, data, setConnected]);

  useEffect(() => {
    let timeoutId = null;

    if (!Object.keys(data).length && user?.token) {
      timeoutId = setTimeout(() => {
        fetchAllData();
      }, 500);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, data, user]);

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  );
};

const useIntegration = () => useContext(IntegrationContext);

export default useIntegration;

import { generateEndpoints } from '.';

const urls = {
  jubelio: '/jubelio',
  iseller: '/iseller',
  'bantu-dagang': '/bantu-dagang',
  bantuDagangKey: '/bantu-dagang/secretKey',
  jubelioKey: '/jubelio-key',
};

export const integrationConstant = generateEndpoints(urls);
